import React from 'react';

const OurPhilosophy = () => {


    return (
        <section className="OurPhilosophy_Block" id="OurPhilosophy">

            <div className="OurPhilosophy_Name"
                 data-de="UNSERE PHILOSOPHIE"
                 data-en="OUR PHILOSOPHY"
            >
                OUR PHILOSOPHY
            </div>

            <div className="OurPhilosophy_Text"
                 data-de="Unsere Unternehmensphilosophie basiert auf drei Säulen: Verantwortung, Effizienz und Professionalität.
                 Unsere Mitarbeiter sind das Rückgrat unseres Unternehmens. Zusammen mit unserem motivierten und fürsorglichen Team sind wir bestrebt,
                 durch den Einsatz modernster Technologien und die kontinuierliche Verbesserung unserer Prozesse die besten Ergebnisse für unsere Kunden zu erzielen."
                 data-en="Our company’s philosophy is based on three pillars: responsibility,
                efficiency, and professionalism, while our people are the backbone of the
                business. Driven forward by our motivated and caring team, we strive to achieve the best results for
                our customers by using cutting-edge technologies and continuously improving our processes."
            >
                Our company’s philosophy is based on three pillars: responsibility,
                efficiency, and professionalism, while our people are the backbone of the
                business. Driven forward by our motivated and caring team, we strive to achieve the best results for
                our customers by using cutting-edge technologies and continuously improving our processes.
            </div>

        </section>
    );
}

export default OurPhilosophy;
